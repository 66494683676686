<script setup>
import Button from '@/components/ui/button/index.vue';
import HeaderLinks from '@/layouts/partials/HeaderLinks.vue';
import WidgetTournaments from "@/components/widget/tournaments/index.vue";
import Suggestions from '@/components/discussion-forums/Suggestions.vue';
import Channels from '@/components/discussion-forums/Channels.vue';
import Subscribed from '@/components/discussion-forums/Subscribed.vue';
import { useResponsive } from '@/composable/responsive';
import { ForumContext } from '@/components/discussion-forums/ForumContext.js';
import { Tabs, Tab, Icon } from '@bignerve/ui-core';
import { createReusableTemplate } from '@vueuse/core';
import { usePlatform } from '@/composable/platform';
import { useAuth } from '@/composable/auth';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { isMobile } from '@/utils/Helpers';
import { ref } from 'vue';

const route = useRoute();
const menuOpen = ref(!isMobile());
const responsive = useResponsive();
const { auth } = useAuth();
const { currentPlatform } = usePlatform();
const [ DefineMenuItem, MenuItem ] = createReusableTemplate();
const [ DefineMenuHeader, MenuHeader ] = createReusableTemplate();

const toggleMenu = () => {
	menuOpen.value = !menuOpen.value;
}

watch(route, () => {
	toggleMenu();
})
</script>

<template>
    <DefineMenuItem #="{ to, label, icon, disabled }">
        <Component :is="disabled ? 'div' : Tab" :to="!disabled && to" :disabled="disabled" :class="{ disabled }" class="text-dust-800 px-4 py-3 rounded-lg flex items-center leading-none cursor-pointer hover:text-dust-900 focus:text-dust-900 sm:rounded-none sm:rounded-l-lg" active-class="border-dust-400 bg-white border sm:border-r-0">
            <Icon :as="icon" class="mr-2" />
            {{ label }}
        </Component>
    </DefineMenuItem>

	<DefineMenuHeader #="{ label }">
		<div class="mx-4 mt-4 border-b border-dust-400">
			<h3 class="text-dust-600 font-semibold text-sm mb-1">
				{{ label }}
			</h3>
		</div>
	</DefineMenuHeader>

	<ForumContext>
		<div class="bg-[#f3f5f8]">
			<div class="container mx-auto py-6 flex flex-col relative items-start md:flex-row gap-4">
				<div class="grid gap-4 w-full">
					<HeaderLinks>

						<div class="flex items-center justify-between">
							<h2 class="mb-0">
								DISCUSSIONS for <span class="text-blue">{{ currentPlatform?.title }}</span>
							</h2>

							<Button variant="outline" type="button" class="gap-2 bg-transparent sm:hidden rounded-lg" @click="toggleMenu">
								<span class="leading-none">Menu</span>
								<Icon :as="menuOpen ? 'chevron-up' : 'chevron-down'" />
							</Button>
						</div>

					</HeaderLinks>

					<div class="flex flex-col relative items-start sm:flex-row">
						<Tabs class="z-[1] w-full mb-4 sm:min-w-[10rem] sm:max-w-[12rem] sm:sticky sm:top-14" :class="{ 'hidden sm:block': !menuOpen }">
							<MenuItem label="Highest Dope" icon="kpi-chart" :to="{ name: 'discussion-forums.highest-dope' }" />
							<MenuItem label="Most Recent" icon="calendar" :to="{ name: 'discussion-forums.most-recent' }"  />
							<MenuItem label="Topics" icon="th-solid" :to="{ name: 'discussion-forums.topics' }"  />
							<MenuItem label="In Challenges" icon="question" :to="{ name: 'discussion-forums.in-challenges' }" />

							<Channels #header>
								<MenuHeader label="Custom" />
							</Channels>

							<!-- <template v-if="['80695491-5ad5-4efd-aac3-838ee1dd5c7a'].includes(currentPlatform?.id)">
								<MenuItem label="Conspiracies" icon="th-solid" :to="{ name: 'discussion-forums.category', params: { category: 'conspiracies' } }"  />
								<MenuItem label="Hypotheses" icon="th-solid" :to="{ name: 'discussion-forums.category', params: { category: 'hypotheses' } }"  />
							</template>
								-->

							<Subscribed v-if="auth.authenticated" #header>
								<MenuHeader label="Subscribed" />
							</Subscribed>

							<template v-if="auth.authenticated">
								<MenuHeader label="Recent 5" />
								<Suggestions :limit="5" />
							</template>

							<MenuHeader label="Shortcuts" />

							<MenuItem
								label="Leaderboard"
								icon="stream"
								:to="{ name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform', race: 'commentator' }, query: { role: 'commentator' } }"
							/>
							
							<!-- <MenuItem label="RPG" icon="storage" :to="{ name: 'discussion-forums.platform', params: { platformSlug: 'rpg' } }" :disabled="true" /> -->
							<!-- <MenuItem label="Robotics" icon="storage" :to="{ name: 'discussion-forums.platform', params: { platformSlug: 'robotics' } }" :disabled="true" /> -->
							<!-- <MenuItem label="Space" icon="storage" :to="{ name: 'discussion-forums.platform', params: { platformSlug: 'space' } }" :disabled="true" /> -->
							<!-- <MenuItem label="Bowie" icon="user-avatar" :to="{ name: 'discussion-forums.platform', params: { platformSlug: 'bowie' } }" :disabled="true" /> -->
							
							<template v-if="auth.authenticated">
								<!-- <MenuItem label="Mentions" icon="mention" :to="{ name: 'discussion-forums.mentions' }" /> -->
								<MenuItem label="My Forum" icon="user-avatar" :to="{ name: 'discussion-forums.my-frieds' }" :disabled="true" />
								<MenuItem label="My Saved" icon="bookmark-regular" :to="{ name: 'discussion-forums.my-saved' }" :disabled="true" />
								<MenuItem label="1:1 Chats" icon="comments-o" :to="{ name: 'discussion-forums.one-to-one' }" :disabled="true" />
								<MenuItem label="My Friends" icon="friends" :to="{ name: 'discussion-forums.my-frieds' }" :disabled="true" />
							</template>
						</Tabs>

						<div id="discussion-forums-container" class="flex flex-col gap-2 bg-white border border-dust-400 px-5 py-6 flex-1 -ml-[1.5px] sm:min-h-full">
							<Suspense>
								<RouterView />

								<template #fallback>
									<div class="flex flex-col gap-2 w-full">
										<div class="h-[1rem] bg-dust-200 rounded-lg animate-loading w-[200px]"></div>
										<div class="h-[10rem] bg-dust-200 rounded-lg animate-loading w-full"></div>
										<div class="h-[10rem] bg-dust-200 rounded-lg animate-loading w-full"></div>
										<div class="h-[10rem] bg-dust-200 rounded-lg animate-loading w-full"></div>
										<div class="h-[10rem] bg-dust-200 rounded-lg animate-loading w-full"></div>
									</div>
								</template>
							</Suspense>
						</div>
					</div>
				</div>

				<div class="z-[1] sm:m-0 w-full mb-4 sm:min-w-[10rem] md:max-w-[18rem] sm:sticky">
					<WidgetTournaments
						:platformName="currentPlatform?.title"
						:platformSlug="currentPlatform?.slug"
						raceType="commentator"
						class="sm:sticky sm:top-[3.5rem]"
					>
						<template #header>
							<h2 class="font-narrow text-white my-0 font-bold leading-none">
								<NIcon as="discussion-icon" class="mr-2" /> Forum Participants:
							</h2>
						</template>
					</WidgetTournaments>
				</div>
			</div>
		</div>
	</ForumContext>
</template>
