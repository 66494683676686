export const STATUS = {
    OPEN: 'open',
    CLOSED: 'closed'
}

export const TYPE = {
    PUBLIC: 'public',
    PRIVATE: 'private'
}

export const PLATFORM_TYPE = {
    USER: 'user-platform',
    SYSTEM: 'system-platform'
}

export const REFERENCE = {
    RENERVE: 'renerve',
    PLATFORM: 'platform'
}
