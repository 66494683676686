<script setup>
import KebabCase from 'lodash/kebabCase';
import { api } from '@/modules/services';
import { useAuth, useLinks as useAuthLinks } from '@/composable/auth';
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useFloating } from '@/composable/floating';

const props = defineProps({
	label: {
		type: String,
	},
	menuTitle: {
		type: String,
	},
	subject: {
		type: String,
		default: 'Report',
	},
	placement: {
		type: String,
		default: 'left',
	},
	contentType: {
		type: String,
		required: true,
		validator: (value) => ['challenge', 'mentoring', 'recruit', 'idea'].includes(value),
	},
	contentId: {
		type: String,
		required: true,
	},
	relatedObject: {
		type: String,
	},
	reasons: {
		type: [Object, Array],
		default: () => ['AI Slop', 'Spam', 'Harassing', 'Obscene', 'Off-topic', 'Plagiarized'],
	},
	context: {
		type: Function,
		default: (c) => c,
	},
});

const { loginURL, continueURL } = useAuthLinks();
const { target, ...dropdown } = useFloating();
const { auth } = useAuth();
const router = useRouter();
const route = useRoute();
const reported = ref(false);
const selected = ref(null);
const evidence = ref('');

const title = computed(() => {
	return props.menuTitle || props.label;
});

const normalizedReasons = computed(() => {
	return props.reasons.map((reason) => {
		if (typeof reason === 'string') {
			return {
				label: reason,
				slug: KebabCase(reason),
				auto: true,
			};
		}

		return {
			label: reason.label,
			slug: KebabCase(reason.slug || reason.label),
			auto: !!reason.auto,
		};
	});
});

const reset = () => {
	dropdown.hide();
	reported.value = false;
	selected.value = null;
	evidence.value = '';
};

const sendReport = async ({ reason }) => {
	try {
		await api.platform.reportContent({
			context: props.context(location.href),
			content_type: props.contentType,
			content_id: props.contentId,
			fingerprint: Cookie.get('fingerprint'),
			related_object: props.relatedObject,
			report: reason.slug,
			evidence: evidence.value,
		});

		reported.value = true;

		setTimeout(reset, 6000);
	} catch (error) {
		console.log(error);

		reset();
	}
};

const updateEvidence = (evidence) => {
	evidence.value = evidence;
};

const reportIt = ({ reason }) => {
	if (!auth.authenticated) {
		continueURL.value.searchParams.append('reported', reason.slug);

		history.pushState(null, null, loginURL.value);
	} else {
		sendReport({ reason });
	}
};

const report = () => {
	reportIt({ reason: selected.value });
};

const select = (reason) => {
	evidence.value = '';
	selected.value = reason;

	if (reason.auto) {
		reportIt({ reason });
	}
};

const hide = () => {
	dropdown.hide();
};

onMounted(() => {
	const query = route.query;

	if (query.reported) {
		dropdown.show();

		sendReport({ reason: { slug: query.reported } });

		router.push({ query: { reported: undefined } });
	}
});
</script>

<template>
	<VDropdown
		ref="target"
		theme="report"
		:placement="placement"
		auto-hide
	>
		<button aria-label="button"
			v-tooltip="{ content: title, html: true, disabled: dropdown.showing }"
			type="button"
			v-bind="$attrs"
			class="text-lg text-dust-700 hover:text-catalyst focus:text-catalyst flex items-center leading-tight"
			@keydown.esc="hide"
		>
			<NIcon
				as="frown-regular"
				:left="!!label"
			/>
			<span v-if="label">{{ label }}</span>
			<span
				v-else
				class="sr-only"
			>
				{{ title }}
			</span>
		</button>
		<template #popper>
			<div class="rounded-md bg-white shadow-high p-1 m-3">
				<div class="font-normal bg-catalyst rounded-md min-w-40 h-full flex flex-col">
					<div
						v-if="reported"
						class="flex flex-grow items-center px-6 py-2 bg-catalyst-100 text-catalyst rounded-md"
					>
						{{ $t('component.report-content.notify.reported') }}
					</div>
					<template v-else>
						<div class="inline-flex flex-row flex-wrap items-start justify-start p-2 gap-1">
							<button aria-label="button"
								v-for="(reason, i) of normalizedReasons"
								:key="i"
								:class="{ 'bg-catalyst-400': selected && reason.slug === selected.slug }"
								type="button"
								class="flex justify-center flex-grow items-center px-6 py-2 text-white hover:bg-catalyst-400 rounded-md"
								@click="select(reason)"
							>
								<span>{{ reason.label }}</span>
							</button>
						</div>
						<slot
							name="details"
							:reason="selected"
							:updateEvidence="updateEvidence"
							:report="report"
							:hide="hide"
						/>
					</template>
				</div>
			</div>
		</template>
	</VDropdown>
</template>

<style lang="scss">
.v-popper--theme-report .v-popper__arrow-outer {
	visibility: hidden;
}

.v-popper--theme-report .v-popper__arrow-inner {
	visibility: hidden;
}
</style>
