<script setup>
import NModal from '@/components/NModal.vue';
import FlexTable from '@/components/FlexTable.vue';
import Loading from '@/components/NLoading.vue';
import Button from '@/components/ui/button/index.vue';
import Form from '@/components/form/Form.vue';
import { api } from '@/modules/services';
import { ref, computed, h, inject } from 'vue';
import { Icon } from '@bignerve/ui-core';
import { useChannelSignupList } from '@/queries/forum-tools';
import { cn } from '@/utils/Helpers';
import { orderBy } from 'lodash';

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    },
    platform: {
        type: Object,
        required: true,
    },
})

const showModal = ref(false)
const { data, refetch } = useChannelSignupList(props.platform.slug, { collectorId: props.channel.id, collectorType: 'channel' })

const signups = computed(() => {
    const items = data.value || [];
    return orderBy(items, [
        (item) => item.status === 'pending',
        (item) => item.status === 'approved',
    ], ['desc', 'desc']);
})
const totalSignups = computed(() => signups.value.length)
const totalApproved = computed(() => signups.value.filter(item => item.status === 'approved').length || 0)

const FormProvider = ({ item }, { slots }) => {
    const handleUpdate = async (data) => {
        await api.channel.signupUpdate(props.platform.slug, item.id, {
            email: data.email,
            extras: {
                firstName: data.firstName,
                lastName: data.lastName,
            }
        });
    }

    return h(Form, { data: {
        email: item.email,
        firstName: item.extras?.firstName || item.firstName,
        lastName: item.extras?.lastName || item.lastName,
    }, action: handleUpdate }, slots.default)
}

const ApproveButton = ({ item, type, class: className }, { slots }) => {
    const loading = ref(false)
    const success = ref(false)
    const error = ref(false)

    const handleApprove = async (data) => {
        loading.value = true
        try {
            await api.channel.signupOperate(props.platform.slug, item.id, {
                op: type, // approve or deny
            })

            success.value = true;

            // Refetch the data to update the status
            refetch();
        } catch (e) {
            error.value = true;
        } finally {
            loading.value = false;
        }
    }

    return h(Button, {
        type: 'button',
        variant: 'inverted',
        class: cn('gap-1', className, type === 'approve' ? 'bg-green-500' : 'bg-red-500'),
        onClick: handleApprove,
    }, () => slots.default({ loading: loading.value, success: success.value, error: error.value }))
}

const FormContext = (_, { slots }) => {
    const form = inject('form')

    return slots.default(form)
}
</script>

<template>
    <div class="px-1 py-3 gap-2 rounded-lg border border-dust-300 bg-white grid items-start justify-start">
        <div class="text-secondary ml-4"><b>RESULTS: {{ totalSignups - totalApproved }} pending.</b></div>
        <div class="italic text-dust-900 ml-4">{{ totalApproved }} approved</div>
        <Button type="button" variant="link" class="underline" @click="() => showModal = true">Manage Sign-ups.</Button>
    </div>

    <NModal v-model="showModal" #="{ close }" @open="refetch">
        <div class="overflow-hidden rounded-md flex flex-col w-full max-w-[95dvw] sm:w-[70rem] bg-white">
            <div class="flex justify-between items-center px-3 py-2 bg-secondary text-white">
                <div class="flex items-center">
                    <h3 class="m-0">Collection Form Sign-ups</h3>
                </div>

                <button aria-label="button" type="button" @click="close">
                    <NIcon as="times-circle-regular" />
                </button>
            </div>

            <div class="h-[75vh] overflow-y-auto sb-snug sb-dust-300">
                <div class="border-b py-2 px-4">
                    <p>
                        These are the sign-ups from your channel's <RouterLink
                            :to="{ name: 'discussion-forums.channel.signup.form', params: { user: platform.slug, slug: channel.slug } }"
                            target="_blank" class="italic text-dust-700 m-0"><span class="underline">public collection
                                page.</span>
                            <Icon as="external-link" class="text-sm ml-1" />
                        </RouterLink> Use the <b>Full Member List</b> to view Invite statuses & metrics.
                    </p>
                </div>
                <FlexTable :header="[
                    { text: 'Member ID', value: 'member_id', class: 'sm:col-span-2' },
                    { text: 'Email', value: 'email', class: 'sm:col-span-2' },
                    { text: 'First Name', value: 'firstName', class: 'sm:col-span-2'  },
                    { text: 'Last Name', value: 'lastName', class: 'sm:col-span-2'  },
                    { text: 'Approve?', value: 'approve', class: 'sm:col-span-2' },
                ]" :items="signups"
                    :line-provider="FormProvider"
                    header-class="pt-3 sticky top-0 bg-white sm:grid-cols-10 sm:grid border-b-2 border-dust-300 py-4 mb-2"
                    group-by="status"
                    row-class="sm:grid sm:grid-cols-10 my-2"
                    class="bg-white px-4 pb-4">
                    <template #col:member_id="{ item }">
                        <span class="text-dust">{{ item.extras?.membershipId || '- - -' }}</span>
                    </template>
                    <template #col:email="{ item }">
                        <FormContext v-if="item.status === 'pending'" #="{ fields }">
                            <input type="email" v-model="fields.email" class="px-2 py-1 border rounded-md" />
                        </FormContext>
                        <span v-else>
                            {{ item.email }}
                        </span>
                    </template>
                    <template #col:firstName="{ item }">
                        <FormContext v-if="item.status === 'pending'" #="{ fields }">
                            <input type="text" v-model="fields.firstName" class="px-2 py-1 border rounded-md" />
                        </FormContext>
                        <span v-else>
                            {{ item.firstName }}
                        </span>
                    </template>
                    <template #col:lastName="{ item }">
                        <FormContext v-if="item.status === 'pending'" #="{ fields }">
                            <input type="text" v-model="fields.lastName" class="px-2 py-1 border rounded-md" />
                        </FormContext>
                        <span v-else>
                            {{ item.lastName }}
                        </span>
                    </template>
                    <template #col:approve="{ item }">
                        <div v-if="item.status === 'pending'" class="flex gap-2 mx-auto">
                            <FormContext v-if="item.status === 'pending'" #="{ busy, success, error }">
                            <Button type="submit" variant="inverted" class="gap-2">
                                <Loading v-if="busy" active />
                                {{ success ? 'Saved' : error ? 'Error' : 'Save' }}
                                </Button>
                            </FormContext>
                            <ApproveButton :item="item" type="approve" #="{ loading, success, error }">
                                <Loading v-if="loading" active />
                                {{ success ? 'Yes' : error ? 'Error' : 'Yes' }}
                            </ApproveButton>
                            <ApproveButton :item="item" type="deny" #="{ loading, success, error }">
                                <Loading v-if="loading" active />
                                {{ success ? 'No' : error ? 'Error' : 'No' }}
                            </ApproveButton>
                        </div>
                        <div v-else class="font-bold text-center" :class="item.status === 'approved' ? 'text-green-500' : 'text-red-500'">
                            {{ item.status === 'approved' ? 'Yes' : 'No' }}
                        </div>
                    </template>

                    <template #group-header="{ index }">
                        <div :class="index !== 0 && 'border border-b border-dust-300'"></div>
                    </template>

                    <template #no-results>
                        <div class="flex flex-row justify-center text-dust mt-40 py-4">
                            No members have signed up yet
                        </div>
                    </template>
                </FlexTable>
            </div>
        </div>
    </NModal>
</template>