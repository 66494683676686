<script>
import { h, ref, defineComponent } from 'vue';
import Modal from '@/components/NModal.vue';
import Icon from '@/components/NIcon.vue';

const types = {
    'application/zip': 'ZIP',
    'application/pdf': 'PDF',
    'application/msword': 'DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
    'application/vnd.ms-powerpoint': 'PPT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
    'text/plain': 'TXT',
    'image/jpeg': 'JPG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'image/svg+xml': 'SVG',
    'image/bmp': 'BMP',
    'image/webp': 'WEBP',
    'video/mp4': 'MP4',
    'video/quicktime': 'MOV',
    'audio/mpeg': 'MP3',
}

const AttachmentFile = defineComponent({
    props: {
        type: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        }
    },
    setup(props, { slots }) {
        const show = ref(false)
        const closeButton = h('button', { class: 'absolute top-0 right-0 p-2 text-white bg-black bg-opacity-50 rounded-full h-8 w-8 flex items-center justify-center', onClick: () => show.value = false }, h(Icon, { as: 'close' }))

        // preview videos
        if (props.type.includes('video')) {
            const stream = h('iframe', { src: `${props.link}?autoplay=true&muted=true`, style: 'border: none; position: absolute; top: 0; height: 100%; width: 100%; padding: 10px;', allowfullscreen: true, autoplay: true, allow: 'accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;' })
            const content = h('div', { class: 'relative bg-black rounded-md flex flex-col m-4 w-[92dvw] h-[60dvh] sm:min-h-[600px] sm:w-[800px]'}, stream)

            return () => [
                h('button', { onClick: () => show.value = true, type: 'button', class: 'font-bold underline text-left' }, slots.default()),
                h(Modal, { modelValue: show.value, 'onUpdate:modelValue': value => show.value = value }, () => [content, closeButton]),
            ]
        }

        // preview audio
        if (props.type.includes('audio')) {
            const stream = h('audio', { controls: true, autoplay: true }, h('source', { src: props.link, type: props.type }))
            const content = h('div', { class: 'relative pt-4'}, stream)

            return () => [
                h('button', { onClick: () => show.value = true, type: 'button', class: 'font-bold underline text-left' }, slots.default()),
                h(Modal, { modelValue: show.value, 'onUpdate:modelValue': value => show.value = value }, () => [content, closeButton]),
            ]
        }

        // preview images
        if (props.type.includes('image')) {
            const image = h('img', { src: props.link, class: 'rounded-md object-contain max-w-full max-h-full' })
            const content = h('div', { class: 'relative bg-white p-4 rounded-md m-4 flex items-center justify-center w-[92dvw] h-[80dvh] sm:w-[800px] sm:h-[600px]'}, image)

            return () => [
                h('button', { onClick: () => show.value = true, type: 'button', class: 'font-bold underline truncate text-left' }, slots.default()),
                h(Modal, { modelValue: show.value, 'onUpdate:modelValue': value => show.value = value }, () => [content, closeButton]),
            ]
        }

        return () => h('a', { href: props.link, target: '__blank', class: 'font-bold underline truncate text-left' }, slots.default())
    }
})
</script>

<script setup>
import Icon from '@/components/NIcon.vue';
import UploadFile from './UploadFile.vue';
import Button from '@/components/ui/button/index.vue';
import { ref } from 'vue';
import { cn } from '@/utils/Helpers';
import { h } from 'vue';

const API_BASE_URL = import.meta.env.VUE_APP_API_BASE_URL;
const MAX_TOTAL_FILES = 10;

const props = defineProps({
    editable: {
        type: Boolean,
        default: false,
    },
    class: {
        type: String,
        default: '',
    },
    arrowClass: {
        type: [String, Object, Array],
        default: '',
    },
});

const emit = defineEmits(['uploading']);
const model = defineModel({ type: Array, default: () => [] });
const showUpload = ref(model.value.length === 0);

const handleUploaded = (data) => {
    showUpload.value = false;

    model.value = [...model.value, {
        label: data.name,
        type: data.type,
        link: data.url,
    }];
}

const handleRemove = (index) => {
    model.value = model.value.filter((_, i) => i !== index);

    if (model.value.length === 0) {
        showUpload.value = true;
    }
}

const handleCancel = () => {
    //
}

const LinkFile = (props) => {
    return h('a', { href: '', target: '__blank', class: 'text-inherit flex items-center justify-center' }, [
        h(Icon, { as: 'link-solid', class: 'text-nerve-600 text-sm' }),
    ])
}

const LinkType = (props) => {
    return h('span', { class: 'px-2 py-[1px] bg-nerve-200 rounded-lg text-xs' }, [
        props.type.includes('video') ? 'MP4-Stream' : (types[props.type] || props.type)
    ])
}
</script>

<template>
    <div :class="cn('relative flex flex-col sm:flex-row w-full items-start gap-4 sm:gap-8 border p-2 sm:p-4 rounded-md bg-white shadow my-6', props.class)">
        <div class="flex gap-2 items-center">
            <Icon as="attach" class="text-dust-600 text-lg font-bold" />
            <strong>Attachments</strong>
        </div>
        
        <div class="flex flex-col gap-2 w-full">

            <ul v-if="model.length" class="pl-0 m-0">
                <li v-for="(attachment, index) of model" :key="attachment.link" class="flex items-center gap-2 px-2 relative">
                    <AttachmentFile :type="attachment.type" :link="attachment.link">
                        {{ attachment.label }}
                    </AttachmentFile>

                    <LinkType :type="attachment.type" />

                    <Button v-if="editable" type="button" variant="icon" @click="handleRemove(index)">
                        <Icon as="close-circle"/>
                    </Button>

                    <!-- <LinkFile class="absolute top-1 right-0 " /> -->
                </li>
            </ul>

            <template v-if="editable">
                <UploadFile v-if="showUpload" :accept="Object.keys(types)" class="border border-dashed p-4 rounded-lg" @uploaded="handleUploaded" @cancel="handleCancel" @uploading="state => emit('uploading', state)" />

                <Button v-else-if="model.length < MAX_TOTAL_FILES" class="w-full border-dashed bg-transparent" @click="showUpload = true">
                    Add
                </Button>
            </template>
        </div>

        <div :class="cn('arrows-top arrows-top-[18px] shadow-lg arrows-border-dust-300 absolute left-20 arrows-bg-white', arrowClass)"/>
    </div>
</template>