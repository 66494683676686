<script setup>
import { Icon } from '@bignerve/ui-core';
import { RouterLink } from 'vue-router';
import { truncate } from 'lodash';
import { useSuggestions } from '@/queries/discussion';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { usePlatform } from '@/composable/platform';
import { TYPE } from '@/constant/platform';
import { SUGGESTION_TYPE } from '@/constant/forum';

const props = defineProps({
    limit: {
        type: Number,
        default: 5,
    },
});

const route = useRoute();
const { data, isFetching } = useSuggestions();
const { currentPlatform } = usePlatform();

const items = computed(() => {
    return data.value?.slice(0, props.limit);
});

const suggestions = computed(() => {
	return items.value?.filter( suggestion =>
		suggestion.slug !== route.params.user &&
		suggestion.slug !== currentPlatform.value?.slug
	);
});

const channels = computed(() => {
    return items.value?.filter( suggestion =>
        suggestion.suggestion_type === SUGGESTION_TYPE.CHANNEL
    );
});

const platforms = computed(() => {
    return items.value?.filter( suggestion =>
        suggestion.suggestion_type === SUGGESTION_TYPE.PLATFORM
    );
});

const resolveSuggestionProps = (suggestion) => {
	if (suggestion.type === TYPE.SYSTEM) {
		return {
			href: `${suggestion.url}/platform/discussion-forums`,
		}
	}

	return {
		to: { name: 'discussion-forums.most-recent', params: { user: suggestion.slug } },
	}
}
</script>

<template>

    <div class="max-h-[14rem] overflow-y-auto overflow-x-hidden sb-tight sb-dust-400 mr-1">
        <template v-if="suggestions?.length">
            <RouterLink v-for="suggestion of channels" :key="suggestion.id" :to="{ name: 'discussion-forums.channel', params: { user: suggestion.platform?.slug || 'platform', slug: suggestion.slug } }" class="text-dust-800 px-4 py-3 rounded-lg flex items-center leading-none cursor-pointer hover:text-dust-900 focus:text-dust-900 sm:rounded-none sm:rounded-l-lg gap-2">
                <img :src="`/img/category-icons/${suggestion.icon}`" :alt="suggestion.name" class="h-4 w-4 filter invert-[70%] drop-shadow-md" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
                {{ suggestion.name }}
            </RouterLink>

            <Component v-for="suggestion of platforms"
                :key="suggestion.id"
                :is="suggestion.type === TYPE.SYSTEM ? 'a' : RouterLink"
                v-bind="resolveSuggestionProps(suggestion)"
                class="text-dust-800 px-4 py-3 rounded-lg inline-flex items-center leading-none cursor-pointer hover:text-dust-900 focus:text-dust-900 sm:rounded-none sm:rounded-l-lg"
            >
                <Icon :as="suggestion.type === TYPE.SYSTEM ? 'storage' : 'user-avatar'" class="mr-2" />
                {{ truncate(suggestion.title, { length: 20 }) }}
            </Component>
        </template>
        <div v-else-if="isFetching" class="grid gap-6 py-3">
            <div v-for="i of 4" :key="i" class="animate-loading px-3 flex items-center gap-1">
                <div class="h-[1.5rem] w-[1.5rem] bg-dust-300 rounded-full"></div>
                <div class="h-[1rem] w-[4rem] bg-dust-300 rounded-md"></div>
                <div class="h-[1rem] w-[2rem] bg-dust-300 rounded-md"></div>
            </div>
        </div>
    </div>
</template>