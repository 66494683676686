<script setup>
import { Icon } from '@bignerve/ui-core';
import { RouterLink } from 'vue-router';
import { truncate } from 'lodash';
import { useSuggestions } from '@/queries/discussion';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { usePlatform } from '@/composable/platform';
import { TYPE } from '@/constant/platform';
import { SUGGESTION_TYPE, CHANNEL_MODE } from '@/constant/forum';
import { useAuth } from '@/composable/auth';


// TODO: Get subscribed channels from API when it's ready
const { data, isFetching } = useSuggestions();
const { auth } = useAuth();

const channels = computed(() => {
    return data.value?.filter( suggestion =>
        suggestion.suggestion_type === SUGGESTION_TYPE.CHANNEL &&
        suggestion.channel_mode === CHANNEL_MODE.PRIVATE &&
        suggestion.platform.owner !== auth.user?.id
    );
});

const resolveSuggestionProps = (suggestion) => {
	if (suggestion.type === TYPE.SYSTEM) {
		return {
			href: `${suggestion.url}/platform/discussion-forums`,
		}
	}

	return {
		to: { name: 'discussion-forums.most-recent', params: { user: suggestion.slug } },
	}
}
</script>

<template>

    <div class="max-h-[12rem] overflow-y-auto overflow-x-hidden sb-tight sb-dust-400 mr-1">
        <template v-if="channels?.length">
            <slot name="header"></slot>
            <RouterLink v-for="channel of channels" :key="channel.id" :to="{ name: 'discussion-forums.channel', params: { user: channel.platform?.slug || 'platform', slug: channel.slug } }" class="text-dust-800 px-4 py-3 rounded-lg flex items-center leading-none cursor-pointer hover:text-dust-900 focus:text-dust-900 sm:rounded-none sm:rounded-l-lg gap-2">
                <img :src="`/img/category-icons/${channel.icon}`" :alt="channel.name" class="h-4 w-4 filter invert-[70%] drop-shadow-md" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
                {{ channel.name }}
            </RouterLink>
        </template>
        <div v-else-if="isFetching" class="grid gap-6 py-3">
            <div v-for="i of 4" :key="i" class="animate-loading px-3 flex items-center gap-1">
                <div class="h-[1.5rem] w-[1.5rem] bg-dust-300 rounded-full"></div>
                <div class="h-[1rem] w-[4rem] bg-dust-300 rounded-md"></div>
                <div class="h-[1rem] w-[2rem] bg-dust-300 rounded-md"></div>
            </div>
        </div>
    </div>
</template>